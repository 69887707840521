import React from "react";

class NoMetaMaskConnection extends React.Component {
  async componentDidMount() {
    window.ethereum.on("accountsChanged", (accounts) => {
      this.props.history.push("/");
    });

    const response = window.ethereum.request({ method: "eth_requestAccounts" })
    if (response.length > 0) {
      this.props.history.push("/");
    }
  }

  async connectToAnMetaMaskAccount() {
    const response = window.ethereum.request({ method: "eth_requestAccounts" })
    if (response.length > 0) {
      this.props.history.push("/");
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });
  }

  render() {
    return (
      <div className="">
        <div className="bg-gradient-to-b from-gray-700 to-gray-600 bottom-0 left-0 right-0 fixed z-40">
          <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
              <div className="border-t border-gray-200 text-center pt-8">
                <h1 className="text-9xl font-bold text-gray-400 py-8">oops!</h1>
                <p className="text-2xl pb-8 px-12 font-medium">
                  It seems that you didn't allow MetaMask on our app. <br />
                  Please allow MetaMask extension to continue on this app.
                </p>
                <button
                  className="bg-gradient-to-br from-yellow-600 to-yellow-700 hover:from-yellow-700 hover:to-yellow-700 text-white font-semibold px-6 py-3 rounded-md"
                  onClick={() => this.connectToAnMetaMaskAccount()}
                >
                  Connect your MetaMask account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NoMetaMaskConnection;
