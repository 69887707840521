import { ethers } from "ethers";
import { Switch, Redirect, withRouter } from "react-router-dom";
import React from "react";
import { CSSTransition } from "react-transition-group";

import "./App.css";

import Authenticate from "./Authenticate/Authenticate";
import TestSuccessChooseService from "./Authenticate/Success";
import Helper from "./Helpers/Helper";
import Login from "./Login/Login";
import NoMetaMaskConnection from "./Errors/NoMetaMaskConnection/NoMetaMaskConnection";
import NoMetaMaskDetected from "./Errors/NoMetaMaskDetected/NoMetaMaskDetected";
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import config from "./config";
import store from "./store";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      guideMode: false,
    };

    this.setPopup = this.setPopup.bind(this);
  }

  async componentDidMount() {
    if (!window.ethereum) {
      this.props.history.push("/noprovider");
    } else {
      const response = window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (!response) {
        this.props.history.push("/noproviderconnection");
      }
      
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      store.dispatch({ type: "setProvider", provider: provider });
      store.dispatch({ type: "setSigner", signer: signer });

      await this.connectToAnMetaMaskAccount();
      await this.setNetwork();
      const abi = [
        "function getAddressFromName(string calldata name) external view returns(address addr)",
      ];
      const erc20 = new ethers.Contract(config["ensContract"], abi, store.getState().signer);
      store.dispatch({ type: "setEnsContract", ensContract: erc20 });
    }
  }

  async connectToAnMetaMaskAccount() {
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .catch((error) => {
        this.props.history.push("/noproviderconnection");
      });
  }

  setPopup() {
    const reverse = !this.state.guideMode;
    store.dispatch({ type: "setGuideMode", guideMode: reverse });
    this.setState({ guideMode: reverse });
  }

  async setNetwork() {
    await window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [config["network"]],
      })
      .then(() => {
        console.log("Connected to the correct network");
      })
      .catch(console.error);
  }

  render() {
    let AppWidth = "transition-width duration-700";
    this.state.guideMode ? (AppWidth += " w-2/3") : (AppWidth += " w-full");
    let classNameHelper =
      "fixed top-0 right-0 bg-gray-100 overflow-hidden mx-auto w-1/3 h-screen";

    return (
      <div className={"App" + AppWidth}>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossOrigin="anonymous"
        ></link>
        <div className="text-center">
          <small>
            You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
            mode.
          </small>
        </div>
        <button
          className="fixed z-40 mx-10 text-green-400 hover:text-green-600 right-3 focus:outline-none"
          onClick={this.setPopup}
        >
          {!this.state.guideMode ? "Start" : "Close"} Manual
        </button>
        <Switch>
          <PublicRoute
            restricted={false}
            component={Login}
            path="/login"
            exact
          />
          <PublicRoute component={Authenticate} path="/authenticate" exact />
          <PrivateRoute
            component={TestSuccessChooseService}
            path="/success"
            exact
          />
          <PublicRoute
            restricted={false}
            component={NoMetaMaskConnection}
            path="/noproviderConnection"
            exact
          />
          <PublicRoute
            restricted={false}
            component={NoMetaMaskDetected}
            path="/noprovider"
            exact
          />
          <Redirect from="*" to="/login" />
        </Switch>
        <CSSTransition
          in={this.state.guideMode}
          timeout={700}
          classNames="guide"
          unmountOnExit
        >
          <div className={classNameHelper}>
            <Helper guideMode={this.state.guideMode} />
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default withRouter(App);
