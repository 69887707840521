import { IdentitySDK } from "@onchain-id/identity-sdk";
import React from "react";
import { withRouter } from "react-router-dom";

import { loadIdentity } from "../modules/identity";
import { login } from "../modules/auth";
import logo from "../assets/logo-com-intech.png";
import store from "../store";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      auth: false,
      disableSignInButton: false,
      error: "",
      guideMode: false,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().guideMode !== this.state.guideMode) {
        this.setState({ guideMode: store.getState().guideMode });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  initErrorState(time) {
    setTimeout(() => this.setState({ error: "" }), time);
  }

  async connect(address, route) {
    if (!address) {
      this.setState({ error: "Please enter an identity address" });
      this.initErrorState(3000);
      return;
    }

    let identity = null;

    try {
      if (!address.startsWith("0x") || address.length !== 42) { 
        const response = await store.getState().ensContract.getAddressFromName(address);

        if (response === "0x0000000000000000000000000000000000000000") {
          this.setState({
            error: "Please enter a valid name or identity address",
          });
          return;
        }
        address = response;
      }
      identity = await loadIdentity(address, store.getState().provider, store.getState().signer);
    } catch (error) {
      console.error(error);
      this.setState({ error: "No identity is known with this address" });
      this.initErrorState(3000);
      return;
    }

    if (!identity) {
      this.setState({ error: "No identity is known with this address" });
      this.initErrorState(3000);
      return;
    }

    const walletPublicKey = store.getState().signer.getAddress();

    //Check if the user is having the public key corresponding to one of the management key of the address
    const hashedUser = IdentitySDK.utils.encodeAndHash(
      ["address"],
      [await walletPublicKey]
    );
    const authorized = await identity.keyHolderInstance.keyHasPurpose(
      hashedUser,
      IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
    );
    if (!authorized) {
      this.setState({
        error: "You don't have the management key for this identity",
      });
      this.initErrorState(3000);
      return;
    }

    store.dispatch({ type: "setIdentity", identity: identity });

    if (this.state.guideMode) {
      this.setState({ disableSignInButton: true });
      store.dispatch({ type: "setHelperState", helperState: "login" });
      store.dispatch({ type: "setAnimationStep", animationStep: 1 });
      login(identity.address);
      store.dispatch({ type: "setNextPage", nextPage: route });
    } else {
      login(identity.address);
      this.props.history.push(route);
    }
  }

  render() {
    return (
      <div className="Login">
        <div className="object-contain w-full sm:mx-auto">
          <img src={logo} className="mx-auto" alt="Logo ComInTech" />
        </div>
        <div className="max-w-xl mx-auto my-10 bg-white p-7 rounded-md shadow-md">
          <div className="text-center">
            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
              Connect with your identity
            </h1>
            <p className="text-gray-400 dark:text-gray-400">
              Identify with your identity address to access to your
              decentralized identity
            </p>
          </div>
          <div className="m-7">
            <div className="mb-6">
              <label
                htmlFor="address_id"
                className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
              >
                Identity address
              </label>
              <input
                type="text"
                name="address_id"
                id="address_id"
                placeholder="Enter your identity adress or your alias here"
                className="w-full text-center px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                                focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                                dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                                dark:focus:border-gray-500"
              />
            </div>
          </div>
          <div className="mb-6">
            <button
              onClick={() =>
                this.connect(
                  document.getElementById("address_id").value,
                  "/authenticate"
                )
              }
              className="w-full px-3 py-4 text-white bg-intech-primary rounded-md focus:bg-intech-secondary focus:outline-none"
            >
              Log in
            </button>
          </div>
        </div>
        {this.state.error && (
          <div className="bg-red-300 border-l-4 border-red-700 text-red-600 max-w-xl mx-auto my-10 p-3 text-left leading-4">
            <p className="font-bold">Whoops !</p>
            <p>{this.state.error}</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Login);
