import React from "react";
import { withRouter } from "react-router-dom";

class NoMetaMaskDetected extends React.Component {

  componentDidMount(){
    if(window.ethereum){
      this.props.history.push("/")
    }
  }

  render() {
    return (
      <div className="">
        <div className="bg-gradient-to-b from-gray-700 to-gray-600 bottom-0 left-0 right-0 fixed z-40">
          <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
              <div className="border-t border-gray-200 text-center pt-8">
                <h1 className="text-9xl font-bold text-gray-400 py-8">oops!</h1>
                <p className="text-2xl pb-8 px-12 font-medium">
                  It seems that you don't have MetaMask installed. <br />
                  Please install the MetaMask extension to continue on this app.
                </p>
                You can find out more about MetaMask and download it by clicking{" "}
                <a
                  className="text-blue-700 underline"
                  href="https://metamask.io/"
                >
                  here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(NoMetaMaskDetected);
