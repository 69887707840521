import React from "react";

import { logout } from "../modules/auth";
import logo from "../assets/logo-com-intech.png";

class TestSuccessChooseService extends React.Component {
  signout() {
    logout();
    this.props.history.push("login");
  }

  render() {
    return (
      <div className="h-screen text-center">
        <div className="object-contain w-full sm:mx-auto">
          <img src={logo} className="mx-auto" alt="Logo ComInTech"/>
        </div>
        <div className="m-12">
          <p className="font-semibold">Successful log in ! You are connected 🎉</p>
          <button
            onClick={() => this.signout()}
            className="bg-intech-primary text-white focus:bg-red-600 focus:outline-none rounded sm:w-auto px-5 py-2 m-2"
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default TestSuccessChooseService;
