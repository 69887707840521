import React from "react";
import { withRouter } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import store from "../store";
import "./helperStyle.css";

class MetamaskHelper extends React.Component {
  constructor() {
    super();
    this.nextStep.bind(this);
    this.completeLogin.bind(this);
    this.state = { transitionState: "inprogress" };
  }

  completeLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "start" });
    store.dispatch({ type: "setAnimationStep", animationStep: 0 });
    this.props.history.push("success");
  };

  uncompleteLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "authenticate" });
    store.dispatch({ type: "setAnimationStep", animationStep: 0 });
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      store.getState().animationStep < 6 &&
        this.setState({ transitionState: "inprogress" });
      store.getState().animationStep === 7 &&
        this.setState({ transitionState: "success" });
      store.getState().animationStep > 10 &&
        this.setState({ transitionState: "error" });
      store.getState().helperState === "start" &&
        this.setState({ transitionState: "start" });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  infoText() {
    switch (store.getState().animationStep) {
      case 0:
        return "The server generates a challenge that will be sign by the user";
      case 1:
        return "A popup opens for the user to sign the challenge";
      case 2:
        return "Waiting for the challenge to be signed";
      case 3:
        return "The signed challenge is sent to the server for verification";
      case 4:
        return "The server retrieves the public key of the identity for a signature verification";
      case 5:
        return "The front asks to the server if you work at InTech";
      case 7:
        return "Your wallet matches and you work at InTech, you are connected!";
      case 10:
        return "You cannot access the next page, your wallet is not valid";
      case 11:
        return "You cannot access the next page, you do not work at InTech";
      default:
        return "";
    }
  }

  nextStep = () => {
    store.dispatch({
      type: "setAnimationStep",
      animationStep: store.getState().animationStep + 1,
    });
  };

  render() {
    return (
      <div className="MetamaskHelper">
        <p className="mx-auto text-justify w-5/6">
          This tutoriel helps you understand Metamask Authentication flow
        </p>
        <div className="h-96 w-full text-xl my-12">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={this.state.transitionState}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div>
                {this.state.transitionState === "inprogress" ? (
                  <div key="animation" className="relative text-md">
                    <div className="grid grid-rows-3 grid-cols-5 mx-7">
                      <div className="col-span-5 self-center">
                        {this.infoText()}
                      </div>
                      {/* <div className={`row-start-3 col-start-5 col-end-6 h-24`}></div> */}
                      <div className="row-start-2 flex flex-col self-center">
                        <i className="fas fa-desktop text-5xl text-purple-600"></i>
                        <p>Front-end</p>
                      </div>
                      {/* Line from Front-end to Wallet */}
                      <div className="flex items-stretch">
                        <div
                          className={`h-0 border-purple-600 border-4 rounded-xl self-center w-full duration-1000 transition-opacity ${
                            store.getState().animationStep === 1 ||
                            store.getState().animationStep === 3
                              ? "opacity-80"
                              : "opacity-30"
                          }`}
                        ></div>
                      </div>
                      {/* Wallet component */}
                      <div className="flex flex-col self-center">
                        <i className="fas fa-wallet text-5xl text-purple-600"></i>
                        <p>Wallet</p>
                      </div>
                      {/* Blockchain component */}
                      <div className="flex flex-col self-center col-start-5">
                        <i className="fas fa-link text-5xl text-purple-600"></i>
                        <p>Blockchain</p>
                      </div>
                      {/* Line from Front-end to the Server */}
                      <div
                        className={`col-start-2 h-24 transition-opacity duration-1000 ${
                          store.getState().animationStep === 0 ||
                          store.getState().animationStep === 3
                            ? "opacity-80"
                            : "opacity-30"
                        }`}
                      >
                        <svg style={{ width: "100%", height: "100%" }}>
                          <line
                            x1="93%"
                            y1="93%"
                            x2="7%"
                            y2="7%"
                            strokeLinecap="round"
                            style={{ stroke: "#7C3AED", strokeWidth: "9" }}
                          />
                        </svg>
                      </div>
                      {/* Line from Server to Blockchain */}
                      <div
                        className={`col-start-4 h-24 transition-opacity duration-1000 ${
                          store.getState().animationStep === 4
                            ? "opacity-80"
                            : "opacity-30"
                        }`}
                      >
                        <svg style={{ width: "100%", height: "100%" }}>
                          <line
                            x1="7%"
                            y1="93%"
                            x2="93%"
                            y2="7%"
                            strokeLinecap="round"
                            style={{ stroke: "#7C3AED", strokeWidth: "9" }}
                          />
                        </svg>
                      </div>

                      <div className="row-start-6 col-start-3">
                        <i className="fas fa-server text-5xl text-purple-600"></i>
                        <p>Server</p>
                      </div>
                    </div>
                    {store.getState().animationStep !== 2 && (
                      <div className="flex justify-end mx-12">
                        <button
                          onClick={this.nextStep}
                          className="outline-none text-gray-700 hover:text-purple-600 transition-colors hover:font-bold"
                        >
                          Next {">"}
                        </button>
                      </div>
                    )}
                  </div>
                ) : this.state.transitionState === "success" ? (
                  <div key="connected" className="relative top-24">
                    <i
                      className="fas fa-check-circle text-green-400"
                      style={{ fontSize: "7rem" }}
                    ></i>
                    <p className="mt-4 mb-12">{this.infoText()}</p>
                    <button
                      className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                      type="button"
                      onClick={this.completeLogin}
                    >
                      Continue
                    </button>
                  </div>
                ) : (
                  this.state.transitionState === "error" && (
                    <div key="connected" className="relative top-24">
                      <i
                        className="fas fa-times-circle text-red-400"
                        style={{ fontSize: "7rem" }}
                      ></i>
                      <p className="mt-4 mb-12">{this.infoText()}</p>
                      <button
                        className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                        type="button"
                        onClick={this.uncompleteLogin}
                      >
                        Done
                      </button>
                    </div>
                  )
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    );
  }
}

export default withRouter(MetamaskHelper);
