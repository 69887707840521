import React from "react";
import { withRouter } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import store from "../store";
import FIDOHelper from "./FIDOHelper";
import GoogleHelper from "./GoogleHelper";
import MicrosoftHelper from "./MicrosoftHelper";
import MetamaskHelper from './MetamaskHelper';
import "./helperStyle.css";

class AuthenticationHelper extends React.Component {
  constructor() {
    super();
    this.state = {
      helperState: store.getState().helperState,
    };
    this.FIDO_HELPER_NAME = "FIDO";
    this.GOOGLE_HELPER_NAME = "Google";
    this.MICROSOFT_HELPER_NAME = "Microsoft";
    this.METAMASK_HELPER_NAME = "Metamask";
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({ helperState: store.getState().helperState });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="LoginHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Authentication Screen</h1>
          <SwitchTransition>
            <CSSTransition
              key={this.state.helperState}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div>
                {this.state.helperState === this.GOOGLE_HELPER_NAME ? (
                  <GoogleHelper />
                ) : this.state.helperState === this.FIDO_HELPER_NAME ? (
                  <FIDOHelper />
                ) : this.state.helperState === this.MICROSOFT_HELPER_NAME ? (
                  <MicrosoftHelper />
                ) : (
                  this.state.helperState === this.METAMASK_HELPER_NAME && <MetamaskHelper />
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </section>
      </div>
    );
  }
}

export default withRouter(AuthenticationHelper);
