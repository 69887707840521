import React from "react";

import store from "../store";
import AuthenticationHelper from "./AuthenticationHelper";
import LoginHelper from "../Helpers/LoginHelper";

class Helper extends React.Component {
  constructor() {
    super();
    this.state = {
      display: false,
      location: store.getState().location,
    };
    this.display = this.display.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({ location: store.getState().location });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  display() {
    this.setState({ display: !this.state.display });
  }

  render() {
    let helperCSS = " hidden";
    this.props.guideMode ? (helperCSS += " hidden") : (helperCSS = "");
    let content = <div>Error with the manual view. Please retry later.</div>;
    switch (this.state.location) {
      case "/authenticate":
        content = <AuthenticationHelper />;
        break;
      case "/login":
        content = <LoginHelper />;
        break;
      case "/success":
        content = (
          <div className="w-full flex flex-col">
            <div className="text-center mt-36" style={{ fontSize: "2rem" }}>
              Congrats, you are connected !
            </div>
            <p className="text-center mx-12 mt-7">
              You can now interact with ComInTech services.
            </p>
            <i
              className="flex fas fa-thumbs-up mx-auto text-gray-800 mt-24"
              style={{ fontSize: "8rem" }}
            ></i>
          </div>
        );
        break;
      default:
        console.error("This location does not exist");
    }

    return <div className="Helper">{content}</div>;
  }
}

export default Helper;
